import Config from '../config';
import * as cookies from './cookies';

export function getUUID() {
    return cookies.getCookie('uuid') || window.localStorage.getItem('uuid') || '';
}

function doFetch(path, method, body, callback, fallback) {
    var options = {
        headers: {
            'Authorization': ``,
            'Content-Type': 'application/json'
        },
        method: method
    }

    if (method === 'POST') {
        options.body = JSON.stringify(body)
        options.data = JSON.stringify(body)
    }

    fetch(Config.API_BASE + path, options)
        .then(response => {
            return response.json()
        })
        .then((data) => {
            if (data.status === 200) {
                callback(data.data)
            } else {
                fallback(data)
            }
        });
}

export function fetchPost(path, data, callback, fallback) {
    doFetch(path, 'POST', data, callback, fallback)
}

export function fetchGet(path, callback, fallback) {
    doFetch(path, 'GET', null, callback, fallback)
}