import FullPageLayout from './layouts/FullPage';
import Review from './pages/Review';
import Errors from './pages/Errors';

export default [
  {
    path: '/:token',
    exact: true,
    layout: FullPageLayout,
    component: Review
  },
  {
    layout: FullPageLayout,
    component: Errors
  },
];
