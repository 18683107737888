import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Progress,
    FormTextarea,
    Button,
    ButtonGroup
} from "shards-react";
import { fetchPost, fetchGet } from '../utils/api';
import ReactStars from 'react-stars';
import { withRouter } from 'react-router-dom';

class Review extends React.Component {
    state = {
        section: 0,
        progress: 0,
        canContinue: false,
        submitted: false,
        job: null,
        googleReview: false,
        url: ''
    }

    componentDidMount() {
        this.onFetchJobDetails()
    }

    sections() {
        const sections = [
            { head: 'Hi, Customer!', description: 'We hope you are satisfied with the level of service you received today. Please help us better it by completing this quick review', image: require('../assets/images/rating.png').default, hasContinue: true },
            { head: 'Issue Fixed', description: "Was your issue resolved? Did the agent fix the fault?", foot: 'thumb down = NO | thumb up = YES', field: 'issue_resolved', boolean: true, hasNext: true },
            { head: 'Agent Conduct', description: "Were you happy with the agent's conduct?", foot: '1 star = TERRIBLE | 5 stars = SUPERB', field: 'agent_interaction', stars: true, hasPrev: true, hasNext: true },
            { head: 'Process Time', description: "Were you happy with how long it took to get your issue resolved or your fault fixed?", foot: '1 star = TOO SLOW | 5 stars = SPEEDY SERVICE', field: 'turnaround_satisfaction', stars: true, hasPrev: true, hasNext: true },
            // { head: 'Product Satisfaction', description: 'Are you generally happy with the product you bought?', foot: '1 star = HATE IT | 5 stars = LOVE IT', field: 'product_satisfaction', stars: true, hasPrev: true, hasNext: true },
            { head: 'Overall Satisfaction', description: 'Are you generally happy with the service you received?', foot: '1 star = TERRIBLE | 5 stars = SUPERB', field: 'overall_satisfaction', stars: true, hasPrev: true, hasNext: true },
            // { head: 'Recommendation', description: 'Are you likely to recommend this product to someone?', foot: 'thumb down = NO | thumb up = YES', field: 'likely_to_recommend', boolean: true, hasPrev: true, hasNext: true, hasComplete: false },
            { head: 'Comments', description: '(optional) Do you have any additional comments, compliments or complaints?', foot: null, optional: true, field: 'review_note', text: true, hasPrev: true, hasNext: false, hasComplete: true }
        ]

        return sections
    }

    onFetchJobDetails() {
        fetchGet('/auth/' + this.props.match.params.token,
            (response) => {
                if (response) {
                    this.setState({ job: response })
                } else {
                    this.setState({ notFound: true })
                }
            },
            (response) => {
                this.setState({ notFound: true })
            }
        )
    }

    onSubmit() {


        if (this.state['agent_interaction'] === 5 || this.state['overall_satisfaction'] === 5 || this.state['turnaround_satisfaction'] === 5) {
            if (this.state.job['job_no'].indexOf('ARC') > -1) {
                this.setState({ googleReview: true, url: 'https://g.page/r/CWwVAeEuEgNhEBM/review' })
            } else {
                this.setState({ googleReview: true, url: 'https://g.page/r/Cb-SS8DWnlH1EBM/review' })
            }
        }
        const body = {
            job_no: this.state.job['job_no'],
            issue_resolved: this.state['issue_resolved'],
            agent_interaction: this.state['agent_interaction'],
            turnaround_satisfaction: this.state['turnaround_satisfaction'],
            product_satisfaction: this.state['product_satisfaction'],
            overall_satisfaction: this.state['overall_satisfaction'],
            likely_to_recommend: this.state['likely_to_recommend'],
            review_note: this.state['review_note'],
            agent: this.state.job['agent'],
            google_reviewed: this.state.googleReview
        }

        fetchPost('/submit/' + this.props.match.params.token, body,
            (response) => {
                if(this.state.googleReview){
                    this.setState({ section: this.state.section + 1, progress: this.state.progress + 1, submitted: false })
                }else{
                    this.setState({ section: this.state.section + 1, progress: this.state.progress + 1, submitted: true })
                }
            },
            (response) => {
                console.log('error', response)
            }
        )

    }

    render() {
        const percent = (progress) => {
            return (progress / 7) * 100
        }

        if (this.state.complete || (this.state.job && this.state.job.state === 'completed')) {
            <Container fluid className='main-content-container m-auto'>
                <div className='text-center'>
                    <h3 className='text-center'><strong className='text-muted d-block mb-2 text-center'>Service Evaluation</strong></h3>
                </div>
                <Row>
                    <Col lg="12" className="mb-2">
                        <Card small className="mb-4">
                            <CardBody className="p-4 pb-3">
                                <div>
                                    <Col
                                        sm='12' md='12'
                                    >
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <img
                                                id='main-logo'
                                                className='d-inline-block align-top mb-4'
                                                style={{ maxWidth: '120px', margin: 'auto' }}
                                                src={require('../assets/images/rating.png')}
                                                alt='Image'
                                            />
                                        </div>
                                        <h5><strong className='text-muted d-block mb-2 text-center'>Your review is complete!</strong></h5>
                                        <p className='text-muted d-block mb-2 text-center'>Thank you for taking the time to review our service and provide us with feedback that will help us improve it</p>
                                    </Col>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        }

        if (this.state.submitted) {
            return (
                <Container fluid className='main-content-container m-auto'>
                    <div className='text-center'>
                        <h3 className='text-center'><strong className='text-muted d-block mb-2 text-center'>Service Evaluation</strong></h3>
                    </div>
                    <Row>
                        <Col lg="12" className="mb-2">
                            <Card small className="mb-4">
                                <CardBody className="p-4 pb-3">
                                    <div className='py-3'>
                                        <Col
                                            sm='12' md='12'
                                        >
                                            <div style={{ width: '100%', display: 'flex' }}>
                                                <img
                                                    id='main-logo'
                                                    className='d-inline-block align-top mb-4'
                                                    style={{ maxWidth: '120px', margin: 'auto', maxHeight: '120px' }}
                                                    src={require('../assets/images/done.png').default}
                                                    alt='Image'
                                                />
                                            </div>
                                            <h5><strong className='text-muted d-block mb-2 text-center'>Thank You!</strong></h5>
                                            <p className='text-muted d-block mb-2 text-center'>We appreciate you taking the time to fill out this customer service review</p>

                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            )
        }

        return (
            <Container fluid className='main-content-container m-auto'>
                <div className='text-center'>
                    <h3 className='text-center'><strong className='text-muted d-block mb-2 text-center'>Service Evaluation</strong></h3>
                </div>
                <Row>
                    <Col lg="12" className="mb-2">
                        <Card small className="mb-4">
                            <CardBody className="p-4 pb-3">
                                {/* <Progress theme='accent' value={this.state.progress} className='mb-5'></Progress> */}
                                {this.sections().map((section, index) => {
                                    // if (index === this.state.section) {
                                    var thumbUp = {}
                                    var thumbDown = {}

                                    if (this.state[section.field] !== undefined) {
                                        if (this.state[section.field] === true) {
                                            thumbUp = { color: '#EBB748', fontSize: '3rem' }
                                            thumbDown = {}
                                        } else {
                                            thumbDown = { color: '#EBB748', fontSize: '3rem' }
                                            thumbUp = {}
                                        }
                                    }
                                    return (
                                        <div key={index} className='py-3'>
                                            <Col
                                                sm='12' md='12'
                                            >
                                                {section.image && (<div style={{ width: '100%', display: 'flex' }}>
                                                    <img
                                                        id='main-logo'
                                                        className='d-inline-block align-top mb-4'
                                                        style={{ maxWidth: '120px', margin: 'auto', maxHeight: '120px' }}
                                                        src={section.image}
                                                        alt='Image'
                                                    />
                                                </div>)}
                                                <h5><strong className='text-muted d-block mb-2 text-center'>{section.head}</strong></h5>
                                                <p className='text-muted d-block mb-2 text-center'>{section.description}</p>
                                                {(section.field && section.stars) && (
                                                    <div style={{ width: '100%', display: 'flex' }}>
                                                        <ReactStars
                                                            value={this.state[section.field]}
                                                            count={5}
                                                            onChange={(value) => {
                                                                const state = { message: null, canContinue: true }
                                                                state[section.field] = value
                                                                this.setState(state)
                                                            }}
                                                            half={false}
                                                            size={50}
                                                            color='#868e96'
                                                            color2='#EBB748'
                                                            className='my-2 mx-auto'
                                                        />
                                                    </div>
                                                )}
                                                {(section.field && section.boolean) && (
                                                    <div style={{ width: '168px', display: 'flex', margin: '1rem auto' }}>
                                                        <Button
                                                            type='button'
                                                            onClick={() => {
                                                                const state = { message: null, canContinue: true }
                                                                state[section.field] = false
                                                                this.setState(state)
                                                            }}
                                                            className='mr-2 transparent'>
                                                            <i className='material-icons' style={thumbDown}>thumb_down_alt</i>
                                                        </Button>
                                                        <div style={{ width: '8px' }} />
                                                        <Button
                                                            type='button'
                                                            onClick={() => {
                                                                const state = { message: null, canContinue: true }
                                                                state[section.field] = true
                                                                this.setState(state)
                                                            }}
                                                            className='mr-2 transparent'>
                                                            <i className='material-icons' style={thumbUp}>thumb_up_alt</i>
                                                        </Button>
                                                    </div>
                                                )}
                                                {section.text && <FormTextarea className='mb-1' placeholder='Type...' onChange={(e) => {
                                                    const state = {}
                                                    state[section.field] = e.target.value
                                                    this.setState(state)
                                                }} />}
                                                {section.foot && <p className='text-muted d-block mb-2 text-center'>{section.foot}</p>}
                                                {this.state.message && <p className='d-block my-4 text-center text-danger'>{this.state.message}</p>}
                                                {section.hasComplete && !this.state.googleReview && <Button type='button' rows={5} style={{ width: '100%' }} onClick={() => this.onSubmit()} className='mt-2'>Submit</Button>}

                                            </Col>
                                            <hr />
                                        </div>
                                    );
                                })}

                                {this.state.googleReview && (<Col

                                    sm='12' md='12'>
                                    <div class="alert alert-success text-center" role="alert">
                                        Would you like to rate us on Google? You will be entered into a monthly draw and stand a chance to win a fabulous prize.
                                    </div>
                                    <ButtonGroup className='mb-4'>
                                        <Button theme='danger' type='button' style={{ float: 'right' }} onClick={() => this.setState({ section: this.state.section + 1, progress: this.state.progress + 1, submitted: true })}> No, I am done.</Button>
                                        <Button theme='success' type='button' style={{ float: 'right' }} onClick={() => window.open(this.state.url, '_self')}> Continue</Button>
                                    </ButtonGroup>
                                </Col>)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(Review)
