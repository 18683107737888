import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/shards-dashboards.1.1.0.min.css';
import './assets/main.css';

const App = () => (
  <Router>
    <Switch>
      {routes.map((route, index) => {
        if (route.path) {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={() => {
                return (
                  <route.layout>
                    <route.component />
                  </route.layout>
                )
              }}
            />
          );
        }

        return (
          <Route
            key={index}
            component={() => {
              return (
                <route.layout>
                  <route.component />
                </route.layout>
              )
            }}
          />
        );
      })}
    </Switch>
  </Router>

);

export default App;
