import React from "react";
import { Container, Row, Col } from "shards-react";

const FullPage = ({ children }) => (
    <Container fluid className='full-page'>
        <Row>
            <Col
                className="p-0"
                sm='12'
                md='12'
                lg='12'
                tag="main"
            >
                <div className='center-block'>
                    {children}
                </div>
            </Col>
        </Row>
    </Container>
);

export default FullPage;
