import React from "react";

const Errors = () => (
  <div className="error">
    <div className="error__content">
      <h2>404</h2>
      <h3>Page not found</h3>
      <p>There was a problem on our end. Please try again later.</p>
    </div>
  </div>
);

export default Errors;
